@use 'sass:math';
@import '../../../../../styles/variables';

$gap: 1rem;

.worldWater-StatisticsPanel {
	position: relative;
	width: 0;
	padding: 0;
	display: flex;
	flex-direction: column;
	grid-gap: $gap;
	background: $backgroundColor_light;
	border-right: 1px solid $borderColor_light;
	border-top: 1px solid $borderColor_light;
	overflow-x: hidden;
	overflow-y: auto;
}

.worldWater-StatisticsPanel.is-open {
	width: 45rem;
	padding: $gap;
	@include maxWidth('large') {
		width: 40rem;
	}
	@include maxWidth('medium') {
		width: 35rem;
		padding: .5rem;
		grid-gap: .5rem;
	}
	@include maxWidth('small') {
		width: 93%;
	}
	@include maxWidth('x-small') {
		padding: 0.25rem 0 0 0;
		grid-gap: .5rem;
	}
}