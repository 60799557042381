@use 'sass:math';
@import '../../../styles/variables';

.worldWater-esaLogo {
	display: flex;
	align-items: center;
	justify-content: center;
	@include maxWidth('medium') {
		width: 5rem;
	}
	@include maxWidth('x-small') {
		width: 4rem;
	}
}

.worldWater-esaLogo svg > path {
	fill: var(--base10);
}

.worldWater-esaLogo:hover svg > path {
	fill: var(--accent30);
}
