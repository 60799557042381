@use 'sass:math';
@import '../../../../../../../../styles/variables';

$SectionHeading: "Sen", "Roboto", sans-serif;

// advanced statistics section

.worldWater-StatisticsSection-AdvancedStatistics {
    transition: height 0.5s;
    height: 0;
    background: var(--base05);
    padding: 0 $StatisticsPanelSection_padding;

    @include maxWidth('medium') {
        padding: 0 $StatisticsPanelSection_padding_medium;
    }
}

.worldWater-StatisticsSection-AdvancedStatistics.isOpen {
    height: auto;
    padding: $StatisticsPanelSection_padding;

    @include maxWidth('medium') {
        padding: $StatisticsPanelSection_padding_medium;
    }
    @include maxWidth('x-small') {
        padding: $StatisticsPanelSection_padding_xsmall;
    }
}

.worldWater-AdvancedStatisticsSection {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;

    @include maxWidth('medium') {
        grid-gap: .75rem;
    }
}

.worldWater-AdvancedStatistics-chart {
    height: 16rem;
    @include maxWidth('medium') {
        height: 14rem;
    }
}