@import '../../../../../styles/variables.scss';

.worldWater-WaterLevelPanel {
	position: absolute;
	height: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 600;
	transition: height $elementOpeningTransitionDuration ease 0s;
	background: $backgroundColor_light;
	border-top: 1px solid $borderColor_light;
}

.worldWater-productExplorer.ptr-dark .worldWater-WaterLevelPanel {
	background: var(--accent15);
	border-top: 1px solid var(--accent20);
}

.worldWater-WaterLevelPanel.is-open {
	height: $waterLevelChartHeight;
	@include maxWidth('medium') {
		height: $waterLevelChartHeight_small;
	}
}

.worldWater-WaterLevelPanel-content {
	height: $waterLevelChartHeight;
	overflow: hidden;
	@include maxWidth('medium') {
		height: $waterLevelChartHeight_small;
	}
}
