@use 'sass:math';
@import '../../../../../../../../styles/variables.scss';

.worldWater-AdvancedStatistics-chart.is-SurfaceWaterChanges {
    display: flex;
    flex-direction: column;
    margin-top: .7rem;
    height: fit-content;
}

.worldWater-AdvancedStatistics-SurfaceWaterChanges-chartsContainer {
    display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		width: 100%;
		gap: 0.5rem;
		margin-top: 0.5rem;
}

.worldWater-AdvancedStatistics-SurfaceWaterChanges-chart {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 17.5rem;
	height: 20rem;
}

.worldWater-AdvancedStatistics-SurfaceWaterChanges-chartLabel {
	text-align: center;
	margin: 0;
	color: var(--accent80);
}