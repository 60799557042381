@use 'sass:math';
@import '../../../../../../styles/variables.scss';

// current
.worldWater-PlaceSelect-current {
	height: $headerHeight;
	background-color: var(--accent05);
	padding: 0 0.25rem 0 1rem;
	transition: 0.5s background-color ease-in-out;
	z-index: 99;
	@include maxWidth('medium') {
		padding: 0 0 0 .5rem;
	}
	@include maxWidth('x-small') {

	}
}

.worldWater-productExplorer.ptr-dark .worldWater-PlaceSelect-current {
	background-color: var(--accent15);
}

.worldWater-PlaceSelect.open .worldWater-PlaceSelect-current {
	background-color: var(--accent05);
	position: relative;
}

.worldWater-productExplorer.ptr-dark .worldWater-PlaceSelect.open .worldWater-PlaceSelect-current {
	background-color: var(--accent15);
}

.worldWater-PlaceSelect.open .worldWater-PlaceSelect-current:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 0;
	margin-bottom: -0.1rem;
	width: 100%;
	height: 0.1rem;
	background: var(--accent05);
}

.worldWater-productExplorer.ptr-dark .worldWater-PlaceSelect.open .worldWater-PlaceSelect-current:after {
	background-color: var(--accent15);
}

.worldWater-PlaceSelect-current > div:first-child {
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
}

// value & placeholder
.worldWater-PlaceSelect-current-value {
	color: $textColor_base;
	padding: 0;
	width: 100%;
	font-family: 'Sen', 'Roboto', sans-serif;
}

.worldWater-PlaceSelect-current-placeholder {
	color: $textColor_secondary;
	font-family: 'Sen', 'Roboto', sans-serif;
}

.worldWater-PlaceSelect-current-placeholder > div {
	font-weight: 400;
}

.worldWater-productExplorer.ptr-dark .worldWater-PlaceSelect-current-placeholder {
	color: $textColor_secondary;
}

.worldWater-PlaceSelect-current-value > span,
.worldWater-PlaceSelect-current-placeholder > span {
	font-size: $b1;
	display: block;
	color: $textColor_secondary;
	line-height: 1.15;
	margin-left: 0.05rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.worldWater-productExplorer.ptr-dark .worldWater-PlaceSelect-current-value > span,
.worldWater-productExplorer.ptr-dark .worldWater-PlaceSelect-current-placeholder > span {
	color: $textColor_secondary;
}

.worldWater-PlaceSelect-current-value > div,
.worldWater-PlaceSelect-current-placeholder > div {
	font-size: $b2;
	line-height: 1;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
	@include maxWidth('medium') {
		font-size: $a1;
	}
	@include maxWidth('x-small') {
		font-size: .85rem;
		letter-spacing: -.5px;
	}
}

.worldWater-PlaceSelect-current-value > div {
	font-weight: bold;
}

// icon
.worldWater-PlaceSelect-current .ptr-panther-select-current-icon {
	position: relative;
	width: 2rem;
	flex-basis: 2rem;
	padding: 1.05rem 0.5rem;
}

.worldWater-PlaceSelect-current .ptr-panther-select-current-icon svg {
	width: 1.25rem;
	height: 1.25rem;
}

.worldWater-PlaceSelect-current .ptr-panther-select-current-icon svg .line {
	fill: rgba(var(--accent50rgb), 0.7);
}

// Content
.ptr-panther-select-list.worldWater-PlaceSelect-list {
	padding-top: $headerHeight;
	z-index: 50;
}

.worldWater-PlaceSelect-list.ptr-panther-select-list > div > div {
	height: 100%;
	transition: transform 0.5s ease-in-out;
}

.worldWater-PlaceSelect-content {
	height: 100%;
	background: linear-gradient(var(--accent05) 55%, var(--accent25) 100%);
	background-size: auto 200%;
	background-position: 0 0;
	transition: background-position 0.5s ease-in-out;
	padding: 3rem;
	border-top: 0.1rem solid var(--base0);
	@include maxWidth('medium') {
		padding: 2rem;
	}
	@include maxWidth('x-small') {
		padding: 1rem;
	}
}

.worldWater-productExplorer.ptr-dark .worldWater-PlaceSelect-content {
	background: var(--accent15);
}

.worldWater-PlaceSelect.open .worldWater-PlaceSelect-content {
	background-position: 0 100%;
}

// Simple select
.worldWater-PlaceSelect-SimpleSelect {
	height: 0rem; //TODO
}

// Place map
.worldWater-PlaceMapContainer {
	width: 100%;
	height: 100%;
	border: 1px solid;
	border-image: linear-gradient(to bottom, var(--accent40), var(--accent50)) 1;
}
