@use 'sass:math';
@import '../../../styles/variables';

.ptr-Card {
	background: var(--base0);
	width: 25rem;
	height: 15rem;
	border-radius: 0.25rem;
}

.ptr-Card.is-floating {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
}

.ptr-Card.is-floating:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.ptr-Card.is-disabled {
	pointer-events: none;
}

.ptr-Card.is-floating.is-disabled:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
