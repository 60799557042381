@use 'sass:math';
@import '../../../../../styles/variables';

.worldWater-StyleBasedLegend {
	@include frozenGlassEffect();
	background: $floatingBoxBackground;
	border-radius: 0.25rem;
	box-shadow: $floatingBoxShadow;
	width: 20rem;
	margin-bottom: 0.5rem;

	display: flex;
	flex-direction: column;
	padding: 0.5rem;
	&.expandable {
		cursor: pointer;
	}
}

.worldWater-StyleBasedLegend.is-expanded {
	padding: 0.5rem 0.75rem 0.75rem 0.5rem;
}
