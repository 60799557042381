@use 'sass:math';
@import '../../../styles/variables';

.ptr-PanelControlButton {
	position: absolute;
	top: 0;
	left: 0.5rem;
	background: var(--base0);
	width: 2.25rem;
	height: 1.25rem;
	border-radius: 1.25rem 1.25rem 0 0;
	margin-top: calc(-1.25rem - 1px);
	display: flex;
	justify-content: center;
	padding-top: 0.1rem;
	color: $iconColor_base;
	cursor: pointer;
	border: 1px solid var(--base20);
	border-bottom-width: 0;
	z-index: 99;
}

.ptr-PanelControlButton:hover {
	background: var(--base15);
}

.ptr-PanelControlButton > .ptr-react-icon {
	transition: transform $elementOpeningTransitionDuration ease-in-out;
}

.ptr-PanelControlButton > .ptr-react-icon {
	width: 1.5rem;
	height: 1.5rem;
	margin-top: -0.15rem;
}

.ptr-PanelControlButton.is-collapsed > .ptr-react-icon {
	transform: rotate(180deg);
}
