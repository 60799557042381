@use 'sass:math';
@import '../../../../../../../../styles/variables';

.worldWater-StatisticsSection-infoWrapper {
	padding: 1rem 1.5rem 1.86375rem;
}

.worldWater-StatisticsSection-infoParagraph {
	margin-bottom: 0.3rem;
}

.worldWater-StatisticsSection-infoLabel {
	font-size: 1.1rem;
	margin: 1rem 0 0.5rem;
}