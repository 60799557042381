@use 'sass:math';
@import '../../../../../../../styles/variables';

$border: 2px dashed var(--base15);

.worldWater-StatisticsPanelAttribute {
	display: flex;
	flex-direction: column;
	padding: .35rem .15rem;
	border-bottom: $border;
	@include maxWidth('x-small') {
		padding: .25rem .05rem;
	}
}

.worldWater-StatisticsPanelAttribute:first-child {
	border-top: $border;
}

.worldWater-StatisticsPanelAttributeRow {
	display: flex;
	align-items: flex-end;
}

.worldWater-StatisticsPanelAttributeRow.header {
	align-items: flex-end;
}

.worldWater-StatisticsPanelAttributeCell {

}

.worldWater-StatisticsPanelAttributeCell.title,
.worldWater-StatisticsPanelAttributeCell.areaName {
	width: 50%;
	display: flex;
}

.worldWater-StatisticsPanelAttributeCell.title {
	font-weight: bold;
	color: $textColor_base;
	display: flex;
	align-items: flex-end;
	font-size: $a1;

	@include maxWidth('medium') {
		font-size: $b1;
	}
	@include maxWidth('x-small') {
		font-size: $a0;
	}
}

.worldWater-StatisticsPanelAttributeCell.title.is-shifted {
	margin-bottom: -1rem;
}

.worldWater-StatisticsPanelAttributeCell-color {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 0.5rem;
}

.worldWater-StatisticsPanelAttributeCell-color > span {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0.5rem;
}

.worldWater-StatisticsPanelAttributeCell.areaName {
	color: $textColor_base;
	font-size: $b1;
	text-transform: uppercase;
	@include maxWidth('x-small') {
		font-size: $a0;
	}
}

.worldWater-StatisticsPanelAttributeCell.valueName,
.worldWater-StatisticsPanelAttributeCell.value {
	width: 8rem;
	@include maxWidth('x-small') {
		width: 7rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.worldWater-StatisticsPanelAttributeCell.valueName {
	font-size: .6875rem;
	color: $textColor_tertiary;
}
.worldWater-StatisticsPanelAttributeCell.value {
	font-size: $a1;
	color: $textColor_base;
	font-weight: bold;
	@include maxWidth('x-small') {
		font-size: $b1;
	}
}


.worldWater-StatisticsPanelAttributeCell > span {
	font-size: .6875rem;
}

.worldWater-StatisticsPanelAttributeCell.title > span {
	font-size: $a1;
	margin-left: .25rem;

	@include maxWidth('medium') {
		font-size: $b1;
	}
	@include maxWidth('x-small') {
		font-size: $a0;
	}
}

.worldWater-StatisticsPanelAttributeCell:not(.trend) > span {
	color: $textColor_tertiary;
}

.worldWater-StatisticsPanelAttributeCell.trend {
	width: 5rem;
	display: flex;
	align-items: center;
	color: var(--base60);
	font-weight: bold;
	font-size: .875rem;
	@include maxWidth('x-small') {
		font-size: $a0;
		width: 4rem;
	}
}

.worldWater-StatisticsPanelAttributeCell.trend > span {
	margin-left: .15rem;
}

.worldWater-StatisticsPanelAttributeCell.trend.is-positive {
	color: #43bd4c;
}

.worldWater-StatisticsPanelAttributeCell.trend.is-very-positive {
	color: #019b10;
}

.worldWater-StatisticsPanelAttributeCell.trend.is-negative {
	color: #e15353;
}

.worldWater-StatisticsPanelAttributeCell.trend.is-very-negative {
	color: #b00709;
}

.worldWater-StatisticsPanelAttributeCell.trend .ptr-react-icon {
	width: 1rem;
}

.worldWater-StatisticsPanelAttributeCell.trend.is-neutral .ptr-react-icon {
	transform: rotate(-180deg);
}

.worldWater-StatisticsPanelAttributeCell.trend.is-negative .ptr-react-icon {
	transform: rotate(-135deg);
}

.worldWater-StatisticsPanelAttributeCell.trend.is-very-negative .ptr-react-icon {
	transform: rotate(-90deg);
}

.worldWater-StatisticsPanelAttributeCell.trend.is-positive .ptr-react-icon {
	transform: rotate(135deg);
	margin-right: .3rem;
	@include maxWidth('x-small') {
		margin-right: .15rem;
	}
}

.worldWater-StatisticsPanelAttributeCell.trend.is-very-positive .ptr-react-icon {
	transform: rotate(90deg);
}