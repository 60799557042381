@use 'sass:math';
@import '../../../../../../../../styles/variables';

.worldWater-StatisticsLayerControl {
    display: flex;
    align-items: center;
}

.worldWater-StatisticsLayerControl > span {
    margin-left: .15rem;
    color: $textColor_disabled;
}