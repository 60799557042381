@use 'sass:math';
@import '../../../../../../../styles/variables';

.worldWater-StatisticsPanel-PanelControlBtn {
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	position: absolute;
	height: 3rem;
	padding: 0 0.25rem 0 0.1rem;
	top: calc(50% - (3rem / 2));
	left: 0;
	cursor: pointer;
	@include frozenGlassEffect();
	border: 1px solid $borderColor_light;
	border-left: 0;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 0 .5rem .5rem 0;
}

.worldWater-StatisticsPanel-PanelControlBtn:hover {
	background: rgba(255, 255, 255, 0.9);
}

.worldWater-StatisticsPanel-PanelControlBtn.is-open {
	left: 45rem;
	@include maxWidth('large') {
		left: 40rem;
	}
	@include maxWidth('medium') {
		left: 35rem;
	}
	@include maxWidth('small') {
		left: 93%;
	}
}

.worldWater-StatisticsPanel-PanelControlBtn.is-open .ptr-react-icon {
	color: var(--accent80);
}

.worldWater-StatisticsPanel-PanelControlBtn.is-open .ptr-react-icon {
	transform: rotate(180deg);
}