@use 'sass:math';
@import '../../../../../../../styles/variables.scss';

.worldWater-ViewCard {
	position: relative;
	width: 22rem;
	height: 15rem;
	background: var(--accent0);
	overflow: hidden;
	@include maxWidth('medium') {
		width: 20rem;
		height: 13rem;
	}
	@include maxWidth('x-small') {
		width: 16rem;
		height: 12rem;
	}
}

.worldWater-ViewCard.ptr-Card {
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.worldWater-ViewCard.is-disabled {
	pointer-events: none;
}

.worldWater-ViewCard:not(.is-disabled):hover {
	box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.worldWater-productExplorer.ptr-dark .worldWater-ViewCard:not(.is-disabled):hover {
	background: var(--accent05);
}

.worldWater-ViewCard-title {
	display: flex;
	align-items: center;
	padding: m(1);
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
	@include maxWidth('medium') {
		padding: 1rem;
	}
	@include maxWidth('x-small') {
		padding: .75rem;
	}
}

.worldWater-ViewCard-title > span {
	font-size: $a2;
	color: $textColor_base;
	font-weight: bold;
	flex: 1;
	font-family: 'Sen', 'Roboto', sans-serif;
	@include maxWidth('medium') {
		font-size: $b2;
	}
	@include maxWidth('x-small') {
	}
}

.worldWater-ViewCard.is-disabled .worldWater-ViewCard-title > span {
	color: var(--accent15);
}

.worldWater-ViewCard-content {
	position: absolute;
	bottom: -0.5rem;
	right: -1rem;
	display: flex;
}

// Graphic
.worldWater-ViewCard-graphic {
	width: 11.5rem;
	height: 10rem;
	@include maxWidth('medium') {
		width: 10rem;
		height: 8rem;
	}
	@include maxWidth('x-small') {
		width: 9rem;
		height: 7rem;
	}
}

.worldWater-ViewCard-graphic .polygon {
	stroke: $iconColor_base;
	stroke-width: 1px;
	fill: $iconColor_fill;
}

.worldWater-ViewCard.is-disabled .worldWater-ViewCard-graphic .polygon {
	stroke: $iconColor_fill;
	fill: $iconColor_fill;
}

.worldWater-ViewCard-graphic .line {
	stroke: $iconColor_base;
	stroke-width: 1px;
	fill: none;
	stroke-linejoin: round;
	stroke-linecap: round;
}

.worldWater-ViewCard-graphic .line.thick {
	stroke-width: 2px;
}

.worldWater-ViewCard-graphic .line.superthick {
	stroke-width: 3px;
}

.worldWater-ViewCard.is-disabled .worldWater-ViewCard-graphic .line {
	stroke: $iconColor_fill;
}
