@use 'sass:math';
@import '../../../styles/variables';

.worldWater-Header {
    flex: 0 0 $headerHeight;
    height: $headerHeight;
    max-height: $headerHeight;
    display: flex;
    grid-gap: 0.1rem;
    z-index: 1000;
    background: var(--base0);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}