@use 'sass:math';
@import '../../../styles/variables';

.worldWater-NoDataPlaceholder {
	color: rgba(var(--accent50rgb), 0.6);
	font-style: italic;
	max-width: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 3rem;
}
