@use 'sass:math';
@import '../../../../../../../../styles/variables';

.worldWater-AdvancedStatistics-chartWrapper {
	display: flex;
	flex-direction: column;
}

.worldWater-AdvancedStatistics-chartWrapper:not(:last-child) {
	padding-bottom: m(1);
}

.worldWater-AdvancedStatistics-chartHeader {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: .5rem;
	grid-gap: 1rem;
}

.worldWater-AdvancedStatistics-chartTitle {
	margin: 0;
	//font-family: "Sen", "Roboto", sans-serif;
	font-size: $a1;
	color: $textColor_base;
	flex: 1;
	@include maxWidth('medium') {
		font-size: $b1;
	}
}

.worldWater-AdvancedStatistics-chartTitle > span {
	font-size: $b1;
}

.worldWater-AdvancedStatistics-chartTools {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	grid-gap: .75rem;
}