@use 'sass:math';
@import '../../../../styles/variables';

.worldWater-selectedPlaceControls {
    display: none;
    align-items: center;
    grid-gap: 1rem;
    height: 100%;
    @include maxWidth('large') {
        grid-gap: .5rem;
    }
    @include maxWidth('small') {
        grid-gap: .25rem;
    }
}

.worldWater-selectedPlaceControls.is-open {
    display: flex;
}

.worldWater-Statistics-Tools {
	flex: 1 1;
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 0 0.45rem;
    @include maxWidth('small') {
        padding: 0 0.05rem;
    }
}