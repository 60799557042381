@import '../../../styles/variables';

$ExpandableLabelBorderRadius: 0.25rem;

// container
.ptr-ExpandableLabelsContainer {
	display: block;
}

.ptr-ExpandableLabelsContainer > .ptr-ExpandableLabel {
	float: left;
	margin: 0 0.5rem 0.5rem 0;
}

.ptr-ExpandableLabelsContainer.is-right > .ptr-ExpandableLabel {
	margin: 0 0 0.5rem 0.5rem;
}

// label
.ptr-ExpandableLabel {
	//background: var(--base0);
	border-radius: $ExpandableLabelBorderRadius;
	max-width: 22rem;
	position: relative;
}

.ptr-ExpandableLabel.is-floating {
	box-shadow: $floatingBoxShadow;
}

.ptr-ExpandableLabelHeader {
	display: flex;
	width: 100%;
	border-radius: $ExpandableLabelBorderRadius;
	@include frozenGlassEffect();
	background: $floatingBoxBackground;
}

.ptr-ExpandableLabelHeader-content {
	flex: 1;
}

.ptr-ExpandableLabelHeader-controlButton {
	height: 100%;
	display: flex;
	align-items: center;
	padding: 0 0.15rem;
	border-top-right-radius: $ExpandableLabelBorderRadius;
	border-bottom-right-radius: $ExpandableLabelBorderRadius;
}

.ptr-ExpandableLabelHeader-controlButton .ptr-icon {
	transition: transform 0.25s ease-in-out;
	transform: rotate(270deg);
	width: 0.85rem;
	height: 100%;
}

.ptr-ExpandableLabelBody {
	width: 100%;
	height: 0;
	overflow-y: hidden;
	transition: height 0.25s ease-in-out;
	border-bottom-left-radius: $ExpandableLabelBorderRadius;
	border-bottom-right-radius: $ExpandableLabelBorderRadius;
	position: absolute;
	z-index: 2;
}

.ptr-ExpandableLabel.is-floating .ptr-ExpandableLabelBody {
	box-shadow: $floatingBoxShadow;
}

.ptr-ExpandableLabelBody > div {
	width: 100%;
}

// Expanded
.ptr-ExpandableLabel.is-expanded {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.ptr-ExpandableLabelHeader.is-expanded {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.ptr-ExpandableLabelHeader.is-expanded .ptr-ExpandableLabelHeader-controlButton .ptr-icon {
	transform: rotate(90deg);
}

.ptr-ExpandableLabelBody.is-expanded {
	display: flex;
	height: 10rem;
}

.ptr-ExpandableLabelBody > div {
	height: 10rem;
	overflow-y: auto;
}
