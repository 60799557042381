@use 'sass:math';
@import '../../../../../styles/variables';

$productExplorerHeaderSelectWidth: 20rem;
$productExplorerHeaderSelectWidth_large: 15rem;
$productExplorerHeaderSelectWidth_medium: 12rem;
$productExplorerHeaderSelectWidth_small: 10rem;
$productExplorerHeaderSelectWidth_small_places: 7rem;

.worldWater-ProductExplorerHeader-places {
	flex: 0 0 $productExplorerHeaderSelectWidth;
	@include maxWidth('large') {
		flex: 0 0 $productExplorerHeaderSelectWidth_large;
	}
	@include maxWidth('medium') {
		flex: 0 0 $productExplorerHeaderSelectWidth_medium;
	}
	@include maxWidth('x-small') {
		flex: 0 0 $productExplorerHeaderSelectWidth_small_places;
	}
}
.worldWater-ProductExplorerHeader-views {
	flex: 0 0 $productExplorerHeaderSelectWidth;
	@include maxWidth('large') {
		flex: 0 0 $productExplorerHeaderSelectWidth_large;
	}
	@include maxWidth('medium') {
		flex: 0 0 $productExplorerHeaderSelectWidth_medium;
	}
	@include maxWidth('x-small') {
		flex: 0 0 $productExplorerHeaderSelectWidth_small;
	}
}