@use 'sass:math';
@import '../../../styles/variables'; 
 
.worldWater-Home-tourIcon.ptr-IconTool.is-active {
	background-color: var(--accent50);
}
	
.worldWater-Home-tourIcon .ptr-react-icon {
	border-radius: 50%;
}

// Home view

.worldWater-Home-tourIcon.ptr-IconTool.is-Home {
	position: absolute;
	right: 0.8rem;
	top: 0.8rem;
	color: var(--base0);
	width: 2.7rem;
	height: 2.7rem;
	@include maxWidth('medium') {
		right: 0.6rem;
		top: 0.6rem;
		width: 2rem;
		height: 2rem;
	}
	@include maxWidth('x-small') {
		right: 0.4rem;
		top: 0.4rem;
	}
}

.worldWater-Home-tourIcon.ptr-IconTool.is-Home:hover {
	color: var(--accent30);
	background-color: transparent;
}

.worldWater-Home-tourIcon.ptr-IconTool.is-active.is-Home {
	background-color: var(--accent50);
}

.worldWater-Home-tourIcon.is-Home .ptr-react-icon {
	width: 2rem;
	height: 2rem;
	@include maxWidth('medium') {
		width: 1.5rem;
		height: 1.5rem;
	}
	@include maxWidth('x-small') {
		width: 1.25rem;
		height: 1.25rem;
	}
}

.worldWater-Home-tourIcon.is-Home.is-animating .ptr-react-icon {
	animation-name: tourAnimationIcon;
	animation-duration: 0.65s;
	animation-iteration-count: 15;
	animation-direction: alternate;
	animation-timing-function: ease;
}

@keyframes tourAnimationIcon {
	from {
		transform: scale(1);
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
	}
	to {
		transform: scale(1.3);
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
	}
}