@use 'sass:math';
@import '../../../../../../styles/variables';

.worldWater-LayerLabel {
	min-width: 12rem;
}

.worldWater-LayerLabel.ptr-ExpandableLabel.is-floating {
	box-shadow: $floatingBoxShadow;
}

.ptr-icon {
	width: 1.25rem;
	height: 1.25rem;
}

// Header
.worldWater-LayerLabelHeader {
	display: flex;
	font-size: $b1;
	border-right: 1px solid rgba(var(--base50rgb), 0.2);
}

.worldWater-LayerLabelHeader-color {
	width: 5px;
	background: var(--accent40);
	border-top-left-radius: 0.25rem;
	border-bottom-left-radius: 0.25rem;
}

.ptr-ExpandableLabel.is-expanded .worldWater-LayerLabelHeader-color {
	border-bottom-left-radius: 0;
}

.worldWater-LayerLabelHeader-body {
	padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.worldWater-LayerLabelHeader-title {
	display: block;
	color: $textColor_base;
	font-weight: bold;
	font-size: $b1;
}

.worldWater-LayerLabelHeader-title:first-letter {
	text-transform: uppercase;
}

.worldWater-LayerLabelHeader-period {
	font-size: $b1;
	color: $textColor_base;
	line-height: 1.25;
}

// Content
.worldWater-LayerLabelBody {
	display: flex;
	flex-direction: column;
	width: 100%;
	background: $floatingBoxBackground;
	@include frozenGlassEffect();
}

.ptr-LayerLabelTool {
	display: flex;
	height: 2rem;
	font-size: $b1;
	align-items: center;
	padding: 0 0.5rem;
	// color: $secondaryIconColor;
	color: $textColor_base;
	border-top: 1px solid var(--base25);
	text-decoration: none;
}

.ptr-LayerLabelTool:first-child {
	border-top: 0;
}

.ptr-LayerLabelTool.is-hoverable {
	cursor: pointer;

	&:hover {
		color: var(--base80);
	}
}

.ptr-LayerLabelTool.is-disabled {
	color: var(--base30);
	pointer-events: none;
}

.ptr-LayerLabelTool-title {
	flex: 1;
	white-space: nowrap;
}

.ptr-LayerLabelTool-control {
	height: 100%;
	display: flex;
	align-items: center;
	margin-left: 1rem;
}

.worldWater-Modal {
	background: var(--base05);
}

// metadata modal window

.worldWater-MetadataInfoTitle {
	margin: 1rem 0 0.8rem;
	color: $textColor_base;
	font-size: $a2;

	&::first-letter {
		text-transform: uppercase;
	}

	.worldWater-MetadataInfoTitle-period {
		font-size: $a1;
		color: $textColor_secondary;
		margin-left: 0.5rem;
	}
}
h4.worldWater-MetadataInfoTitle {
	// padding-top: .5rem;
	line-height: 1rem;
}

.worldWater-MetadataInfo {
	padding: 0 m(1) m(1);
	overflow: hidden;
}

.worldWater-MetadataInfoItem {
	max-width: 60rem;
}

.worldWater-MetadataInfoItem:not(:last-child) {
	margin-bottom: m(2);
}

// header
.worldWater-MetadataInfoItemHeader {
	display: flex;
	margin-bottom: m(math.div(1, 3));
}

.worldWater-MetadataInfoItemHeader-title {
	margin: 0;
	flex: 1;
	word-break: break-word;
}

.worldWater-MetadataInfoItemHeader-tools {
	margin-left: 1rem;
	min-width: 7rem;
}

.worldWater-MetadataInfoItemHeader-download.disabled .ptr-icon .line {
	stroke: var(--base35);
}

// basics
.worldWater-Metadata-LayerTemplateItem {
	display: grid;
	justify-content: center;
	column-count: 1;
	column-gap: m(2);

	.worldWater-Metadata-LayerTemplateItemSection {
		max-width: 40rem;
	}
}

// url buttons

.worldWater-MetadataInfoUrl {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	max-width: 40rem;
	gap: 0.5rem;
	color: $textColor_base;
	margin: .5rem 0 m(1);
}

.worldWater-MetadataInfoUrlItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.worldWater-MetadataInfoUrlItem-wmsUrl {
	display: flex;
	align-items: center;
	border: 1px solid rgb(133, 133, 133);
	border-radius: 4px;
	padding: 0.2rem 0.6rem;
	width: -webkit-fill-available;
	height: 100%;
	overflow: hidden;
}

.worldWater-MetadataInfoUrlItem-wmsUrlText {
	white-space: nowrap;
	width: 100%;
	border: none;
	background-color: transparent;
	color: grey;
	font-weight: 500;
}

.worldWater-MetadataInfoUrlItem-wmsUrlText:focus {
	outline: none;
	color: black;
}

.worldWater-MetadataInfoUrlItem-wmsBtn {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0.2rem 0.6rem;
	background-color: transparent;
	cursor: pointer;
	height: 2.5rem;
	width: 13rem;
}

.worldWater-MetadataInfoUrlItem-wmsBtn svg {
	width: 1.3rem;
	height: 1.3rem;
}

.worldWater-MetadataInfoUrlItem-button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.worldWater-MetadataInfoUrlItem-label {
	display: inline-block;
	margin-right: 0.4rem;
	font-size: $a1;
	font-weight: 500;
}

.worldWater-MetadataInfoItemTiles-BtnShowTiles {
	transition: 0.5s;
}

.worldWater-MetadataInfoItemTiles-BtnShowTiles > .ptr-IconTool {
	width: 1.3rem;
	height: 1.3rem;
}

.worldWater-MetadataInfoItemTiles-content {
	font-size: $b1;
	margin: 0 0.5rem 0 0;
}

.worldWater-MetadataInfoItemTiles-content:hover {
	color: black;
}