@use 'sass:math';
@import '../../../../../styles/variables';

.worldWater-StatisticsSelect {
  height: 100%;
}

.worldWater-StatisticsSelect svg {
  width: 16px;
	@media only screen and (max-width: 800px) {
		width: 12px;
	}
	@media only screen and (max-width: 400px) {
		width: 18px;
	}
}

.worldWater-StatisticsSelect-valueContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-left: 6px;
	@media only screen and (max-width: 800px) {
		padding-left: 4px;
	}
}

.worldWater-StatisticsSelect-colorIndicator {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 0.5rem;
}