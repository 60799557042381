@use 'sass:math';
@import '../../../styles/variables';

a.worldWater-HeaderTitle {
    display: flex;
    height: 100%;
    align-items: center;
    padding: .15rem 1rem 0 .25rem;
    text-decoration: none;
    margin-right: .5rem;
    font-family: 'Sen', 'Roboto', sans-serif;
    color: $iconColor_disabled;
    @include maxWidth('large') {
        padding: .15rem .5rem 0 .25rem;
    }
    @include maxWidth('small') {
        padding: .15rem 0 0 .25rem;
        margin-right: .25rem;
    }
    @include maxWidth('x-small') {
        margin-right: 0;
				display: flex;
				flex-direction: column;
    }
}

a.worldWater-HeaderTitle:focus,
a.worldWater-HeaderTitle:hover {
    color: $iconColor_base;
    outline: none;
}

.worldWater-HeaderTitle .ptr-react-icon {
    width: 16px;
    transition: .2s width ease-in-out;
}

a.worldWater-HeaderTitle:hover .ptr-react-icon {
    width: 26px;
}

.worldWater-HeaderLogo {
    width: 2.5rem;
    display: flex;
    align-items: center;
    margin: 0 .35rem 0 .25rem;
    @include maxWidth('large') {
        width: 2rem;
        margin: 0 .35rem 0 .15rem;
    }
    @include maxWidth('x-small') {
        width: 1.5rem;
    }
}

.worldWater-HeaderTitle-name {
    margin: 0;
    font-size: $b2;
    display: flex;
    grid-gap: .5rem;
    @include maxWidth('large') {
        font-size: $a1;
        grid-gap: 0;
        flex-direction: column;
    }
    @include maxWidth('small') {
        display: none;
    }
    @include maxWidth('x-small') {
    }
}

.worldWater-HeaderTitle-name > span {
    font-weight: bold;
    color: $textColor_base;
}

.worldWater-HeaderTitle-name > span:first-child {
    border-right: 2px solid $iconColor_secondary;
    padding-right: .5rem;
    color: $textColor_secondary;
    @include maxWidth('large') {
        padding-right: .25rem;
        border-right: 0;
    }
}