@use 'sass:math';
@import '../../../styles/variables.scss';

.worldWater-DownloadChart {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.worldWater-DownloadChart-menu {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	position: absolute;
	overflow: hidden;
	margin: 1.4rem -0.1rem 0 0;
	z-index: 99;
	height: 0;
	width: max-content;
}

.worldWater-DownloadChart-menu.is-open {
	height: fit-content;
}

.worldWater-DownloadChart-menuConnect {
	width: 1px; 
	height: 1px; 
	border-left: 12px solid transparent;
	border-right: 12px solid transparent;
	border-bottom: 16px solid var(--base05);
	margin-right: 0.2rem;
}

.worldWater-DownloadChart-menuContent {
	display: flex;
	flex-direction: column;
	background-color: var(--base05);
	filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.15));
	margin: 0 0.2rem 0.4rem 0;
}

.worldWater-DownloadChart-menuContent-item {
	width: 100%;
	padding: 0.4rem 0.8rem;
	cursor: pointer;
	font-size: $b1;
}

.worldWater-DownloadChart-menuContent-item:hover {
	background: var(--accent10);
}