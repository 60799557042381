@import '../../../../../../styles/variables';

.worldWater-mapWrapper {
	position: relative;
}

.ptr-map-wrapper.worldWater-mapWrapper.active::after {
	border: 3px dashed var(--accent30);
	z-index: 1;
}

.ptr-map-wrapper.worldWater-mapWrapper.one-map.active:after,
.ptr-map-wrapper.worldWater-mapWrapper.not-highlight-active.active:after {
	border: 0;
}

.worldWater-mapWrapper-mapTools {
	position: absolute;
	top: 0.7rem;
	right: 0.5rem;
	z-index: 2;
	display: flex;
	grid-gap: 0.35rem;
}

// water level map labels
.worldWater-MapExtentLabelsContainer {
	position: absolute;
	top: 0.7rem;
	left: 0.5rem;
	z-index: 2;
	max-width: calc(100% - 2rem);
}

.worldWater-MapExtentLabelsContainer.is-right {
	left: auto;
	right: 0.6rem;
}

.worldWater-MapExtentLabelsContainer > div {
	position: relative;
}
