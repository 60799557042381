@use 'sass:math';
@import '../../../../styles/variables';

.ptr-TimeRangeSelector {
}

.ptr-TimeRangeSelector-content {
	height: 3rem;
	position: relative;
	border: 0.15em solid var(--base20);
	@include maxWidth('medium') {
		height: 2rem;
	}
	@include maxWidth('x-small') {
		height: 1.5rem;
	}
}

// slider
.ptr-TimeRangeSlider {
	position: absolute;
	top: 0;
	height: 100%;
	padding: 0;
	border-radius: 0;
}

.ptr-TimeRangeSlider .rc-slider-rail {
	height: 100%;
	border-radius: 0;
	background: rgba(var(--base50rgb), 0);
}

.ptr-TimeRangeSlider .rc-slider-track {
	height: 100%;
	border-radius: 0;
	background: rgba(var(--accent50rgb), 0.08);
	cursor: grab;
}

.ptr-dark .ptr-TimeRangeSlider .rc-slider-track {
	background: rgba(var(--accent50rgb), 0.25);
}

.ptr-TimeRangeSlider .rc-slider-handle {
	height: calc(100% + 4px);
	border: 0;
	background: var(--base40);
	width: 4px;
	border-radius: 0;
	margin-top: -2px;
	cursor: e-resize;
}

.ptr-TimeRangeSlider .rc-slider-handle.rc-slider-handle-dragging {
	border: 0;
	box-shadow: none;
	background: var(--base60);
}

.ptr-TimeRangeSlider .rc-slider-handle:hover {
	background: var(--base60);
}

// labels
.ptr-TimeRangeSelector-labels {
	display: flex;
	justify-content: space-between;
}

.ptr-TimeRangeSelector-labels > span {
	font-size: $b1;
	color: var(--base50);
}
