@use 'sass:math';
@import '../../../../../styles/variables.scss';

$mapBorder: 0.05rem solid var(--base50); // the width in rem fixes the ugly white space between border and content in Chrome

// Map set
.esaWorldWater .ptr-map-set {
	position: relative;
	z-index: 2;
}

.esaWorldWater .ptr-map-grid .ptr-map-grid-cell .ptr-map {
	border: 0;
}

.esaWorldWater .ptr-map-grid .ptr-map-grid-cell:not(.row1) .ptr-map {
	border-top: $mapBorder;
}

.esaWorldWater .ptr-map-grid .ptr-map-grid-cell:not(.col1) .ptr-map {
	border-left: $mapBorder;
}

// Map slider
.worldWater-CompareSlider {
	height: 100%;
	margin-top: -1px;
}

div[data-rcs='handle-container'] {
	z-index: 3;
}

div[data-rcs='clip-item'] {
	z-index: 2;
}

.ptr-map-controls-wrapper {
	z-index: auto;
}

// Map set tools
.worldWater-MapSetTools {
	bottom: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	transition: bottom $elementOpeningTransitionDuration ease;
	pointer-events: none;

	> * {
		pointer-events: all;
	}
}

.worldWater-MapSetTools.has-chart-open {
	bottom: $waterLevelChartHeight;
	@include maxWidth('medium') {
		bottom: $waterLevelChartHeight_small;
	}
}

.worldWater-MapSetTools.has-timeline-open {
	bottom: $timelineHeight;
	@include maxWidth('medium') {
		bottom: $timelineHeight_small;
	}
}

// MapInfoElements
.worldWater-MapInfoElements {
	pointer-events: none;
	position: absolute;
	bottom: 0.25rem;
	right: 3.35rem;
	display: flex;
	grid-gap: 0.35rem;
	flex-direction: column;
	z-index: 9;
}

// AttributionScaleContainer
.worldWater-AttributionScaleContainer {
	display: flex;
	grid-gap: 0.35rem;
}

// Map set controls
.worldWater-MapSetControls {
	position: absolute;
	right: 0.5rem;
	bottom: 0.5rem;
	grid-gap: 0.5rem;
	display: flex;
	flex-direction: column;
	z-index: 9;
}

// Map Info box
.worldWater-InfoBox {
	pointer-events: none;
	position: absolute;
	top: .5rem;
	right: .5rem;
	display: flex;
	z-index: 9;
}

.ptr-DeckTooltip .worldWater-ProductLabelHeader {
	border-right: none;
	display: flex;
	font-size: 0.75rem;

	.worldWater-ProductLabelHeader-body {
		width: 100%;
		padding: 0.25rem 0.75rem 0.25rem 0.5rem;

		.worldWater-ProductLabelHeader-title {
			align-items: center;
			display: flex;

			.worldWater-ProductLabelHeader-product {
				font-size: 0.75rem;
				font-weight: 700;
				margin-right: 0.25rem;
			}
		}

		.worldWater-ProductLabelHeader-period {
			font-size: 0.625rem;
			line-height: 1rem;
		}

		.worldWater-ProductLabelLegendItem {
			color: var(--base20);
			align-items: center;
			display: flex;
			font-size: 0.75rem;
			font-style: italic;

			> div {
				border: 1px solid var(--base50);
				border-radius: 0.15rem;
				height: 1rem;
				margin-right: 0.35rem;
				width: 1.25rem;
			}
		}
	}
}
