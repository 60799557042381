@use 'sass:math';
@import '../../../../../styles/variables';

// Title
.worldWater-MapLegendTitle {
	line-height: 1;
	display: flex;
}

.worldWater-MapLegendTitle-name {
	font-weight: bold;
	font-size: $a0;
	color: $textColor_base;
	display: flex;
	flex-direction: column;

	&:first-letter {
		text-transform: uppercase;
	}
}

.worldWater-MapLegendTitle:first-letter {
	text-transform: uppercase;
}

.worldWater-MapLegendTitle-unit {
	font-size: $a0;
	margin-left: 0.25rem;
	color: $textColor_base;
}

// Content
.worldWater-MapLegendContent {
	display: flex;
	margin-top: 0.35rem;
	grid-gap: 1rem;
	padding-right: 0.25rem;
	width: 100%;
	align-items: center;
}

.worldWater-MapLegendContent.is-expanded {
	flex-direction: column;
	grid-gap: 0.5rem;
	align-items: flex-start;
}

// Class item
.worldWater-MapLegendClassItem,
.worldWater-MapLegendValueItem {
	display: flex;
	grid-gap: 0.35rem;
}

.worldWater-MapLegendClassItem > div {
	width: 1.25rem;
	height: 0.75rem;
}

.worldWater-MapLegendValueItem > div {
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 0.5rem;
}

.worldWater-MapLegendClassItem > span,
.worldWater-MapLegendValueItem > span {
	font-size: $a0;
	line-height: 1;
	color: $textColor_base;
}

// Classes Bar
.worldWater-MapLegendClassesBar {
	display: flex;
	width: 100%;
	flex: 1;
	align-items: center;
}

.worldWater-MapLegendClassesBar-label {
	font-size: $a0;
	line-height: 1;
	color: $textColor_base;
}

.worldWater-MapLegendClassesBar-bar {
	display: flex;
	flex: 1;
	margin: 0 0.25rem;
	height: 100%;
}

.worldWater-MapLegendClassesBar-bar > div {
	flex: 1;
	height: 0.75rem;
}

.worldWater-MapLegendClassesBar .worldWater-MapLegendClassItem {
	margin-right: 0.75rem;
}

// Attribute classes
.worldWater-MapLegend-AttributeClasses {
	display: flex;
	flex: 1;
}

.worldWater-MapLegend-AttributeClasses.is-expanded {
	flex-direction: column;
	grid-gap: 0.25rem;
}

// Attribute valueTypes
.worldWater-MapLegend-AttributeValues {
	display: flex;
	flex: 1;
	flex-wrap: wrap;
	grid-gap: 0.35rem;
}

.worldWater-MapLegend-AttributeValues.is-expanded {
	flex-direction: column;
}

// Attribute scale
.worldWater-MapLegend-AttributeScale {
	display: flex;
	flex: 1;
}

.worldWater-MapLegend-AttributeScale.is-expanded {
	width: 100%;
}

.worldWater-MapLegend-AttributeScale > span {
	font-size: $a0;
	line-height: 1;
	color: $textColor_base;
}

.worldWater-MapLegend-AttributeScale > div {
	flex: 1;
	height: 0.75rem;
	margin: 0 0.25rem;
}
