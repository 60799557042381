@use 'sass:math';
@import '../../../styles/variables';

.worldWater-Home {
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/img/intro_background.jpg');
	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;
	font-family: 'Sen', 'Roboto', sans-serif;
}

.worldWater-Home-overlay {
	width: 100%;
	height: 100%;
	background-color: rgba(5, 44, 80, 0.8);
	overflow: auto;
	padding: 6rem 1rem 2rem;
	@include maxWidth('medium') {
		padding: 5rem 1rem 2rem;
	}
	@include maxWidth('x-small') {
		padding: 4.5rem 1rem 2rem;
	}
}

.worldWater-Home-content {
	min-height: -webkit-fill-available;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
}

a.worldWater-Home-webLink {
	position: absolute;
	left: 1rem;
	top: 1rem;
	font-size: $b2;
	color: var(--base0);
	cursor: pointer;
	display: flex;
	align-items: center;
	text-decoration: none;
	@include maxWidth('medium') {
		font-size: $a1;
	}
	@include maxWidth('x-small') {
		font-size: $b1;
	}
}

a.worldWater-Home-webLink:hover,
a.worldWater-Home-webLink:visited:hover {
	text-decoration: underline;
	color: var(--accent35);
}

.worldWater-Home-webLink > .ptr-icon {
	margin-left: .35rem;
	width: 1.5rem;
	height: 1.5rem;
	@include maxWidth('medium') {
		margin-left: .25rem;
		width: 1.25rem;
		height: 1.25rem;
	}
	@include maxWidth('x-small') {
		width: 1rem;
		height: 1rem;
	}
}

.worldWater-Home-webLink > .ptr-icon .line {
	fill: var(--base0);
}

.worldWater-Home-webLink:hover > .ptr-icon .line {
	fill: var(--accent35);
}

.worldWater-Home-mainSection {
	display: flex;
	flex: 1;
	justify-content: flex-start;
	flex-direction: column;
}

.worldWater-Home-header {
	width: 100%;
	grid-gap: m(0.8);
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	@include maxWidth('x-small') {
		grid-gap: .75rem;
	}
}

.worldWater-Home-headerText {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.worldWater-Home-card-titleContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 0.2rem;
}

.worldWater-Home-title {
	font-size: $a4;
	color: var(--base05);
	text-align: center;
	margin: 0;

	@include maxWidth('medium') {
		font-size: $a3;
	}
	@include maxWidth('x-small') {
		font-size: $b3;
	}
}

.worldWater-Home-title span {
	font-weight: 600;
	color: var(--accent30);
}

.worldWater-Home-subtitle {
	font-size: $b3;
	color: var(--base05);
	margin: 0;

	@include maxWidth('medium') {
		font-size: $b2;
	}
	@include maxWidth('x-small') {
		font-size: $a1;
	}
}

.worldWater-Home-headerlogo {
	width: 7rem;
	height: 7rem;

	@include maxWidth('medium') {
		width: 5rem;
		height: 5rem;
	}
	@include maxWidth('x-small') {
		width: 4rem;
		height: 4rem;
	}
}

.worldWater-Home-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	grid-gap: m(1);
	margin: m(3) 0;
	@include maxWidth('medium') {
		margin: m(2) 0;
		grid-gap: 1rem;
	}
	@include maxWidth('x-small') {
		margin: m(1.5) 0;
		grid-gap: 1rem;
		max-width: 20rem;
	}
}

.worldWater-Home-card-link {
	text-decoration: none;
}

.worldWater-Home-card-link.disabled {
	pointer-events: none;
}

.worldWater-Home-card {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 20rem;
	height: 11rem;
	background-color: rgba(255, 255, 255, 0.75);
	border-bottom: 7px solid $iconColor_accented;
	padding: 1.5rem;
	@include frozenGlassEffect();

	@include maxWidth('medium') {
		width: 16rem;
		height: 10rem;
		padding: 1rem;
	}
	@include maxWidth('x-small') {
		width: 14rem;
		height: 9rem;
		padding: .75rem;
	}
}

.worldWater-Home-card-link.disabled .worldWater-Home-card {
	background-color: rgba(150, 150, 150, 0.6);
	border-bottom: 7px solid var(--base50);
}

.worldWater-Home-card:hover {
	background-color: rgba(255, 255, 255, 0.85);
}

.worldWater-Home-card-title-externalLink {
	width: 1.5rem;
	height: 1.5rem;
	fill: $iconColor_base;

	@include maxWidth('medium') {
		width: 1.25rem;
		height: 1.25rem;
	}
	@include maxWidth('x-small') {
		width: 1rem;
		height: 1rem;
	}
}

.worldWater-Home-card-icon > .ptr-react-icon {
	width: 3rem;
	height: 3rem;
	color: $iconColor_accented;

	@include maxWidth('medium') {
		width: 2.5rem;
		height: 2.5rem;
	}
	@include maxWidth('x-small') {
		width: 2rem;
		height: 2rem;
	}
}

.worldWater-Home-card-link.disabled .worldWater-Home-card.is-outsideLink .worldWater-Home-card-titleContainer .worldWater-Home-card-title-externalLink {
	fill: var(--base45);
}

.worldWater-Home-card-link.disabled .worldWater-Home-card-icon > .ptr-react-icon {
	color: var(--base45);
}

.worldWater-Home-card-title {
	margin: 0;
	text-decoration: none;
	color: $textColor_base;
	@include maxWidth('medium') {
		font-size: $b2;
	}
	@include maxWidth('x-small') {
		font-size: $b2;
	}
}

.worldWater-Home-card-link.disabled .worldWater-Home-card-title {
	color: var(--base45);
}

.worldWater-Home-card-description {
	margin: 0;
	color: $textColor_base;
}

.worldWater-Home-card-link.disabled .worldWater-Home-card-description {
	color: var(--base45);
}

.worldWater-Home-footer {
}
