@use 'sass:math';
@import '../../../../../../../styles/variables';

.worldWater-PointStatisticsSection {
	min-width: 22rem;
	max-width: 22rem;
	@include maxWidth('medium') {
		min-width: 14rem;
		max-width: 14rem;
	}
	@include maxWidth('small') {
		min-width: 12rem;
		max-width: 12rem;
	}
	@include maxWidth('x-small') {
		display: none;
	}
}

.worldWater-PointStatisticsSection .worldWater-DashboardSection-body {
	display: flex;
	flex-direction: column;
	grid-gap: 0.5rem;
	@include maxWidth('medium') {
		grid-gap: 0.25rem;
	}
	@include maxWidth('x-small') {
		grid-gap: 0.15rem;
	}
}

.worldWater-PointStatisticsSection-content {
	display: flex;
	grid-gap: 1.5rem;
	width: 100%;
	padding: 1rem 0;
	@include maxWidth('medium') {
		grid-gap: 1rem;
		padding: .5rem 0;
		flex-direction: column;
		overflow-y: auto;
	}
	@include maxWidth('x-small') {
		grid-gap: .5rem;
		padding: .25rem 0;
	}
}

.worldWater-PointStatisticsSection-content > div {
	display: flex;
	flex-direction: column;
	grid-gap: 1.5rem;
	width: 100%;
	@include maxWidth('medium') {
		grid-gap: .75rem;
	}
	@include maxWidth('x-small') {
		grid-gap: .5rem;
	}
}

.worldWater-StatisticItem {
	width: 100%;
}

.worldWater-StatisticItem-title {
	font-size: $b1;
	font-weight: 400;
	color: var(--base65);
	line-height: 1;
}

.worldWater-StatisticItem-content {
	display: flex;
	align-items: baseline;
}

.worldWater-StatisticItem-number,
.worldWater-StatisticItem-text {
	font-size: $b2;
	font-weight: bold;
	color: $textColor_base;
	@include maxWidth('medium') {
		font-size: $a1;
		padding-top: .1rem;
	}
	@include maxWidth('x-small') {

	}
}

.worldWater-StatisticItem-content > span{
	font-size: $b1;
	color: $textColor_tertiary;
	margin-left: .15rem;
}

.worldWater-StatisticItem-text:first-letter {
	text-transform: uppercase;
}

.worldWater-StatisticItem-deviation {
	font-size: $b1;
	color: var(--base50);
	margin-left: 0.25rem;
}
