@import '../../../../styles/variables';

.ptr-DeckTooltip {
	// max-width: 20rem;
	background-color: transparent;
	border: none;
	box-shadow: none;

	> div {
		@include frozenGlassEffect();
		background-color: rgba(var(--base100rgb), .6);
		border-radius: 0.25rem;
		border: 1px solid rgba(255, 255, 255, 0.125);
		box-shadow: $floatingElementShadow;
		color: var(--base20);
	}

	.info {
		display: flex;
		padding: 0.5rem;
		font-size: $b1;
		font-style: italic;
	}

	.worldWater-ProductLabelHeader-color {
		width: 5px;
		background: var(--base50);
		border-top-left-radius: 0.25rem;
		border-bottom-left-radius: 0.25rem;
	}
}

// Water level tooltip
.worldWater-WaterLevelMapTooltip {
	padding: .5rem 1rem .5rem .65rem;
}

.worldWater-WaterLevelMapTooltip-type,
.worldWater-WaterLevelMapTooltip-id {
	font-size: $b1;
	color: var(--base20);
}

.worldWater-WaterLevelMapTooltip-type:first-letter {
	text-transform: uppercase;
}

.worldWater-WaterLevelMapTooltip-title {
	font-size: $a1;
	font-weight: bold;
	color: var(--base10);
	line-height: 1.25;
}

