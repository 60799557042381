@use 'sass:math';
@import '../../../styles/variables';

.worldWater-Tour-steps-container {
	padding: 0 0.5rem;
}

.worldWater-Tour-steps-title {
	margin: 0 0 0.5rem;
}

.worldWater-Tour-steps-subtitle {
	margin: 0 0 0.5rem;
}

.worldWater-Tour-steps-sectionsContainer {
	display: flex;
	flex-direction: row;
}

.worldWater-Tour-steps-section {
	margin-top: 0.7rem;
}

.worldWater-Tour-steps-section.is-right {
	margin-left: 1rem;
}