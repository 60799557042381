@use 'sass:math';
@import '../../../../styles/variables';

.ptr-InfoBox {
    background: var(--accent50);
    box-shadow: $floatingBoxShadow;
    border-radius: .25rem;
    padding: .25rem .5rem;
    color: var(--base0);
    font-size: $b1;
    opacity: 1;
    transition-property: opacity;
    transition-duration: .5s;
}

.ptr-InfoBox.toBeHidden {
    opacity: 0;
}
