@use 'sass:math';
@import '../../../../../../../styles/variables';

.worldWater-ProgressChartSection {
	flex: 1;
}

.worldWater-nivo-tooltip {
	background: var(--base0);
	color: inherit;
	font-size: $b2;
	padding: 0;

	.worldWater-nivo-tooltip-content {
		white-space: pre;
		display: flex;
		flex-flow: column;
		align-items: flex-start;

		h2 {
			font-size: $b2;
			color: var(--base90);
			margin: 0;

			.worldWater-nivo-tooltip-color {
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				margin-right: 0.35rem;
			}
		}

		h4 {
			color: var(--base90);
			margin: 0;

			.worldWater-nivo-tooltip-color {
				display: inline-block;
				width: 10px;
				height: 10px;
				border-radius: 5px;
				margin-right: 0.35rem;
			}
		}

		> div {
			margin-top: 0.5rem;
			display: flex;
			flex-flow: row;
		}

		span.worldWater-nivo-tooltip-time {
			color: var(--base50);
			font-size: $b1;
		}

		span.worldWater-nivo-tooltip-label {
			display: inline-block;
			color: var(--base50);
			font-size: $a1;
			margin-right: 0.25rem;

			&::first-letter {
				text-transform: uppercase;
			}
		}
		span.worldWater-nivo-tooltip-value {
			color: var(--base90);
			font-weight: bold;
			font-size: $a1;
			margin-right: 0.25rem;
		}
		span.worldWater-nivo-tooltip-deviation,
		span.worldWater-nivo-tooltip-value > span {
			color: var(--base50);
			font-size: $a1;
		}

		span.worldWater-nivo-tooltip-value > span {
			margin-left: .15rem;
		}
	}
}
