@use 'sass:math';
@import '../../../../../../../styles/variables';

.worldWater-WaterLevelPanelConfig {
	flex: 1;
	display: flex;
	grid-gap: 1.5rem;
	@include maxWidth('medium') {
		grid-gap: .75rem;
	}
	@include maxWidth('x-small') {
		grid-gap: .25rem;
	}
}

.worldWater-WaterLevelPanelConfig .ptr-button-caption {
	@include maxWidth('x-small') {
		display: none;
	}
}

.worldWater-ButtonSwitcher-shortName {
	@include maxWidth('x-small') {
		font-size: $b1;
		padding: calc(0.4rem - 1px) 0 calc(0.291rem - 1px);
		line-height: 1;
	}
}

.worldWater-ButtonSwitcher-buttonSwitch.ptr-button:not(.active):not(.disabled) > .worldWater-ButtonSwitcher-shortName {
	color: var(--accent50);
}