@use 'sass:math';
@import '../../../../../styles/variables';

.worldWater-StatisticsMap {
    flex: 1;
    display: flex;
    background: var(--base05);
    @include maxWidth('small') {
        overflow: hidden;
    }
}

.worldWater-statistics-MapSetControls {
    position: absolute;
    right: 0.5rem;
    bottom: 0.5rem;
    grid-gap: 0.5rem;
    display: flex;
    flex-direction: column;
    z-index: 9;
}