@use 'sass:math';
@import '../../../../../../styles/variables';

.worldWater-WaterLevelDashboard {
	height: calc($waterLevelChartHeight - 2rem);
	display: flex;
	padding: 1rem;
	grid-gap: 1rem;
	@include maxWidth('medium') {
		height: calc($waterLevelChartHeight_small - 2rem);
		padding: .75rem;
		grid-gap: .75rem;
	}
	@include maxWidth('x-small') {
		padding: .5rem 0 0 0;
	}
}
