@use 'sass:math';
@import '../../../../../../../styles/variables';

.worldWater-DashboardSection {
	background: var(--base0);
	padding: 0.75rem 1.25rem 1rem 1rem;
	border-radius: 0.15rem;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
	display: flex;
	flex-direction: column;
	@include maxWidth('medium') {
		padding: 0.55rem .5rem .5rem .5rem;
	}
	@include maxWidth('x-small') {
		padding: .75rem .65rem .65rem .65rem;
	}
}

.worldWater-DashboardSection-header {
	display: flex;
	justify-content: space-between;
	margin-right: 0.5rem;
	@include maxWidth('medium') {
		margin-right: 0.25rem;
	}
	@include maxWidth('x-small') {
		margin-right: 0;
	}
}

.worldWater-DashboardSection-title {
	font-size: $a1;
	font-weight: bold;
	letter-spacing: 0.3px;
	height: 2rem;
	color: $textColor_base;
	@include maxWidth('medium') {
		font-size: $b1;
		letter-spacing: 0;
		height: 1.75rem;
	}
	@include maxWidth('x-small') {

	}
}

.worldWater-DashboardSection-body {
	height: calc(100% - 2rem);
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@include maxWidth('medium') {
		height: calc(100% - 1.75rem);
	}
	@include maxWidth('x-small') {

	}
}
