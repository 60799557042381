@import '../../../../styles/variables';

.ptr-MapAttribution {
	z-index: 2;
	position: relative;
	font-size: .7rem;
	color: var(--base50);
	padding: 0.1rem 0.35rem;
	@include maxWidth('small') {
		font-size: .55rem;
	}
}

.ptr-MapAttribution a {
	color: var(--base50);
}
