@use 'sass:math';
@import '../../../../../../../../../styles/variables.scss';

.worldWater-AdvancedStatistics-SurfaceWaterChangesLegend {
    display: flex;
    justify-content: flex-start;
    grid-gap: 1rem;
    margin: 0 10px;
}

.worldWater-AdvancedStatistics-SurfaceWaterChangesLegend-item {
    display: flex;
    align-items: center;
    grid-gap: .3rem;
    overflow: hidden;
}

.worldWater-AdvancedStatistics-SurfaceWaterChangesLegend-icon {
    width: .8rem;
    min-width: .8rem;
    height: .8rem;
}

.worldWater-AdvancedStatistics-SurfaceWaterChangesLegend-label {
    font-size: $b1;
    color: var(--base50);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
}