@use 'sass:math';
@import '../../../styles/variables';

.worldWater-ButtonSwitcher {
	display: flex;
	grid-gap: 0.35rem;
	align-items: center;
	color: var(--accent50);
	@include maxWidth('small') {
		grid-gap: 0.15rem;
	}
}

.worldWater-ButtonSwitcher-buttonSwitch.ptr-button {
	border: 1px solid $iconColor_accented;
}

.worldWater-ButtonSwitcher-buttonSwitch.ptr-button:hover {
	background: var(--accent15);
}

.worldWater-ButtonSwitcher-buttonSwitch.ptr-button:not(.active):not(.disabled) > .ptr-button-caption {
	color: var(--accent50);
}

// Condensed
.worldWater-ButtonSwitcher.is-condensed {
	grid-gap: .1rem;
}

.worldWater-ButtonSwitcher.is-condensed .ptr-react-icon {
	width: 1.125rem;
	height: 1.125rem;
	color: $iconColor_base;
}

.worldWater-ButtonSwitcher.is-condensed .ptr-button.ghost.small {
	padding: 0 .1rem;
}

.worldWater-ButtonSwitcher.is-condensed .worldWater-ButtonSwitcher-buttonSwitch.ptr-button {
	background: none;
	border-radius: 0;
	border: none;
}

.worldWater-ButtonSwitcher.is-condensed .worldWater-ButtonSwitcher-buttonSwitch.ptr-button:focus:after {
	box-shadow: none;
}

.worldWater-ButtonSwitcher.is-condensed .worldWater-ButtonSwitcher-buttonSwitch.ptr-button .ptr-button-caption {
	color: $textColor_base;
}

.worldWater-ButtonSwitcher.is-condensed .worldWater-ButtonSwitcher-buttonSwitch.ptr-button.active {
	background: none;
	border-bottom: .1rem dashed var(--accent30);
}

.worldWater-ButtonSwitcher.is-condensed .worldWater-ButtonSwitcher-buttonSwitch.ptr-button:hover {
	background: $hoverBackgroundColor;
}

.worldWater-ButtonSwitcher.is-condensed .worldWater-ButtonSwitcher-buttonSwitch.ptr-button.active .ptr-button-caption {
	color: $textColor_secondary;
	font-weight: bold;
}