@import '../../../styles/variables';

.ptr-OpacitySlider {
	width: 3rem;
	height: 0.2rem;
	border-radius: 5rem;
	margin-right: 0.25rem;
}

.ptr-OpacitySlider-thumb {
	margin-top: -.4rem;
	width: 1rem;
	height: 1rem;
	border-radius: 0.85rem;
	background: var(--base15);
	color: var(--accent10);
	border: .2rem solid $iconColor_base;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: $b1;
	cursor: pointer;
}

.ptr-OpacitySlider-thumb:hover {
	background: var(--base30);
}

.ptr-OpacitySlider-track {
	top: 0;
	bottom: 0;
	border-radius: 999px;
}

.ptr-OpacitySlider-track-0 {
	background: $iconColor_base;
}

.ptr-OpacitySlider-track-1 {
	background: var(--base30);
}
