@use 'sass:math';
@import '../../../../styles/variables';

.worldWater-StatisticsBody {
    flex: 1 1;
    height: calc(100% - $headerHeight);
    position: relative;
    overflow: hidden;
    display: flex;
    width: 100%;
    flex-wrap: nowrap;
}