@use 'sass:math';
@import '../../../../../../styles/variables';

.worldWater-WaterLevelPanelHeader {
	height: 2rem;
	display: flex;
	padding: 1rem 1rem 0;
	@include maxWidth('medium') {
		padding: .75rem .75rem 0;
	}
	@include maxWidth('x-small') {
		padding: .5rem .25rem 0;
	}
}
