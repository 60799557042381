@use 'sass:math';
@import '../../../../../../../../../../styles/variables';

.worldWater-Metadata-LayerTemplateItemSection-title {
	font-size: $b2;
	margin: 0 0 0.5rem;
	font-weight: 500;
	color: $textColor_base;
}

.worldWater-Metadata-LayerTemplateItemSection-paragraph {
	font-size: $a1;
	color: $textColor_base;
}

.worldWater-Metadata-LayerTemplateItemSection-paragraphTitle {
	font-weight: bold;
	color: $textColor_base;
}

.worldWater-Metadata-LayerTemplateItemSection-legendInfoTitle {
	font-weight: bold;
	color: $textColor_base;
}

.worldWater-Metadata-LayerTemplateItemSection-image {
	width: 40rem;
	margin-bottom: 0.5rem;
}

.worldWater-Metadata-LayerTemplateItemSection-legendInfo {
	margin-bottom: 1rem;
	display: flex;
	flex-direction: column;
	color: $textColor_base;
}