@import '../../../styles/variables';

.worldWater-nivo-tooltip-time-alternative {
	font-size: $a1;
	color: var(--base90);
	margin: 0;
}

.worldWater-nivo-tooltip-item-alternative {
	font-size: $a1;
	margin-top: 0.75rem;
	color: var(--base90);
	display: flex;
	align-items: center;
	flex-direction: row;
}

.worldWater-nivo-tooltip-color-alternative {
	display: inline-block;
	width: 10px;
	height: 10px;
	border-radius: 5px;
	margin-right: 0.35rem;
}