@use 'sass:math';
@import '../../../../styles/variables';

.worldWater-MapLegends {
	position: absolute;
	bottom: 2rem;
	right: 0.7rem;
	z-index: 2;
	display: flex;
	flex-direction: column;
}

.worldWater-MapLegends.is-left {
	right: auto;
	left: 0.5rem;
}
.worldWater-MapLegends.is-right {
	right: 3.5rem;
	left: auto;
}

// components
.worldWater-ColorClasses {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.worldWater-ColorClassesBar {
	height: 0.5rem;
	padding: 0 1rem;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.worldWater-ColorClassesBarItem {
	flex: 1;
}

.worldWater-ColorClassesValues {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin: 0.1rem 0 0 0.3rem;
}

.worldWater-ColorClassesValues.is-firstAndLast {
	justify-content: space-between;
}

.worldWater-ColorClassesValues.without-unit .worldWater-ColorClassesValue:last-child {
	padding: 0;
	flex: 0.5;
}

.worldWater-ColorClassesValue,
.worldWater-ColorClassesValuesUnit {
	flex: 1;
	font-size: $b1;
	color: var(--base50);
	text-align: center;
	padding-left: 0.3rem;
	display: flex;
}

.worldWater-ColorClassesValue:last-child {
	padding-left: 0;
	flex: 0;
}

.worldWater-ColorClassesValues.is-firstAndLast .worldWater-ColorClassesValue {
	flex: 0;
}

.worldWater-ColorClassesValuesUnit {
	font-size: $b1;
	color: var(--base50);
}

.worldWater-MapLegendExpandInfo {
	font-size: $b1;
	color: var(--base50);
}
