@use 'sass:math';
@import '../../../../styles/variables';

.ptr-CoordinatesControl-box {
    position: absolute;
    right: 2.5rem;
    top: 0;
    background: $floatingBoxBackground;
    padding: .35rem .5rem;
    border-radius: .25rem;
    box-shadow: $floatingBoxShadow;
    min-width: 14rem;
}

.ptr-CoordinatesControl-box > span {
    font-size: $b1;
    font-style: italic;
    color: $textColor_tertiary;
}