@use 'sass:math';
@import '../../../../../styles/variables';

.worldWater-HydrobasinSelect {
    min-width: 15rem;
    max-width: 20rem;

    @include maxWidth('large') {
        min-width: 13rem;
        max-width: 16rem;
    }

    @include maxWidth('medium') {
        min-width: 8rem;
        max-width: 10rem;
    }

    @include maxWidth('small') {
        min-width: 4rem;
        max-width: 8rem;
    }

		@media only screen and (max-width: 400px) {
			max-width: 6rem;
		}
}