@use 'sass:math';
@import '../../../../../styles/variables';

.worldWater-StatisticsHeaderControls {
    height: 100%;
    display: flex;
    align-items: center;
    grid-gap: 1rem;

    @include maxWidth('large') {
        grid-gap: .5rem;
    }
    @include maxWidth('small') {
        grid-gap: .25rem;
    }
}