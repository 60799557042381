@use 'sass:math';
@import '../../../../../../../styles/variables';

.worldWater-StatisticsPanel-Header {
	text-wrap: wrap;
	color: $textColor_secondary;
	padding: 0 .1rem;
	font-size: $a1;
	margin-bottom: -.25rem;

	@include maxWidth('small') {
		justify-content: center;
	}
	@include maxWidth('x-small') {
		font-size: $b1;
		padding-top: .15rem;
	}
}