@import '~@gisatcz/ptr-core/lib/styles/_variables';

$a1: .9375rem;
$a0: .75rem;

$lightAccent0: hsl(40, 100%, 99.41%);
$lightAccent0rgb: 255, 254, 252;
$lightAccent05: hsl(197.14, 67.74%, 93.92%);
$lightAccent10: hsl(199.09, 75.86%, 88.63%);
$lightAccent15: hsl(200.6, 78.82%, 83.33%);
$lightAccent20: hsl(200, 78.95%, 77.65%);
$lightAccent25: hsl(200.18, 80.14%, 72.35%);
$lightAccent30: hsl(200.15, 81.07%, 66.86%);
$lightAccent35: hsl(200.25, 81.63%, 61.57%);
$lightAccent40: hsl(200.54, 82.14%, 56.08%);
$lightAccent45: hsl(200.58, 82.47%, 50.78%);
$lightAccent50: hsl(200.35, 100%, 45.1%);
$lightAccent50rgb: 0, 152, 230;
$lightAccent55: hsl(200.29, 100%, 40.59%);
$lightAccent60: hsl(200.33, 98.92%, 36.27%);
$lightAccent65: hsl(200.25, 98.77%, 31.76%);
$lightAccent70: hsl(200.29, 97.14%, 27.45%);
$lightAccent75: hsl(199.65, 96.58%, 22.94%);
$lightAccent80: hsl(199.33, 95.74%, 18.43%);
$lightAccent85: hsl(199.09, 91.67%, 14.12%);
$lightAccent90: hsl(198.14, 87.76%, 9.61%);
$lightAccent95: hsl(195.79, 70.37%, 5.29%);
$lightAccent100: hsl(45, 100%, 0.78%);
$lightAccent100rgb: 4, 3, 0;

$darkAccent0: hsl(45, 100%, 0.78%);
$darkAccent0rgb: 4, 3, 0;
$darkAccent05: hsl(195.79, 70.37%, 5.29%);
$darkAccent10: hsl(198.14, 87.76%, 9.61%);
$darkAccent15: hsl(199.09, 91.67%, 14.12%);
$darkAccent20: hsl(199.33, 95.74%, 18.43%);
$darkAccent25: hsl(199.65, 96.58%, 22.94%);
$darkAccent30: hsl(200.29, 97.14%, 27.45%);
$darkAccent35: hsl(200.25, 98.77%, 31.76%);
$darkAccent40: hsl(200.33, 98.92%, 36.27%);
$darkAccent45: hsl(200.29, 100%, 40.59%);
$darkAccent50: hsl(200.35, 100%, 45.1%);
$darkAccent50rgb: 0, 152, 230;
$darkAccent55: hsl(200.58, 82.47%, 50.78%);
$darkAccent60: hsl(200.54, 82.14%, 56.08%);
$darkAccent65: hsl(200.25, 81.63%, 61.57%);
$darkAccent70: hsl(200.15, 81.07%, 66.86%);
$darkAccent75: hsl(200.18, 80.14%, 72.35%);
$darkAccent80: hsl(200, 78.95%, 77.65%);
$darkAccent85: hsl(200.6, 78.82%, 83.33%);
$darkAccent90: hsl(199.09, 75.86%, 88.63%);
$darkAccent95: hsl(197.14, 67.74%, 93.92%);
$darkAccent100: hsl(40, 100%, 99.41%);
$darkAccent100rgb: 255, 254, 252;

@mixin frozenGlassEffect {
	backdrop-filter: blur(6px) saturate(180%);
	-webkit-backdrop-filter: blur(6px) saturate(180%);
}

// Breakpoints
$width-breakpoints: (
	x-small: 575.98px,
	small: 767.98px,
	medium: 991.98px,
	large: 1199.98px,
	x-large: 1399.98px,
	xx-large: 1599.98px,
	xxx-large: 2599.98px
);

$height-breakpoints: (
	x-small: 575.98px,
	small: 767.98px,
	medium: 991.98px,
	large: 1199.98px,
	x-large: 1399.98px,
	xx-large: 1599.98px,
	xxx-large: 2599.98px
);

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin maxWidth($breakpoint) {
	// If the key exists in the map
	@if map-has-key($width-breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media (max-width: map-get($width-breakpoints, $breakpoint)) {
			@content;
		}
	}

		// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($width-breakpoints)}.";
	}
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin minWidth($breakpoint) {
	// If the key exists in the map
	@if map-has-key($width-breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media (min-width: map-get($width-breakpoints, $breakpoint)) {
			@content;
		}
	}

		// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($width-breakpoints)}.";
	}
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin maxHeight($breakpoint) {
	// If the key exists in the map
	@if map-has-key($height-breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media (max-height: map-get($height-breakpoints, $breakpoint)) {
			@content;
		}
	}

		// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($height-breakpoints)}.";
	}
}

/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin minHeight($breakpoint) {
	// If the key exists in the map
	@if map-has-key($height-breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media (min-height: map-get($height-breakpoints, $breakpoint)) {
			@content;
		}
	}

		// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($height-breakpoints)}.";
	}
}

// Colors
@function hexToRGB($color) {
	@return red($color), green($color), blue($color);
}


/* --- General --- */
// Sizes
$headerHeight: 3.25rem;

$iconSize: 1.25rem;
$iconToolSize_medium: 2rem;

// Colors
$backgroundColor_light: var(--accent05);
$backgroundColor_accented: var(--accent50);

$borderColor_light: var(--accent10);
$borderColor_accented: var(--accent50);
$borderColor_disabled: var(--base25);

$hoverBackgroundColor: rgba(var(--base50rgb), 0.12);
$hoverBackgroundColor-dark: rgba(var(--base50rgb), 0.25);

$textColor_base: var(--accent80);
$textColor_secondary: var(--accent50);
$textColor_tertiary: var(--base50);
$textColor_placeholder: var(--accent30);
$textColor_disabled: var(--base30);
$textColor_inverted: var(--accent0);

$iconColor_base: var(--accent80);
$iconColor_secondary: var(--accent50);
$iconColor_accented: var(--accent50);
$iconColor_disabled: var(--base30);
$iconColor_inverted: var(--accent0);

$iconColor_fill: var(--accent25);

// Animations
$elementOpeningTransitionDuration: 400ms;

// Floating elements
$floatingBoxBackground: rgba(var(--base0rgb), 0.85);
$floatingBoxShadow: 0 5px 10px rgba(0, 0, 0, 0.15);
$floatingElementShadow: 0 2px 5px rgba(0, 0, 0, 0.35);

/* --- Product explorer --- */
$timelineHeight: 10rem;
$timelineHeight_small: 8rem;
$waterLevelChartHeight: 24rem;
$waterLevelChartHeight_small: 18rem;

/* --- Statistics --- */
$StatisticsPanelSection_padding: 1rem;
$StatisticsPanelSection_padding_medium: .75rem;
$StatisticsPanelSection_padding_xsmall: .35rem;