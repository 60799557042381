@use 'sass:math';
@import '../../../styles/variables.scss';

.worldWater-productExplorer {
	height: 100%;
	display: flex;
	flex-direction: column;
}

.worldWater-productExplorer-content {
	flex: 1 1;
	height: calc(100% - $headerHeight);
	position: relative;
	overflow: hidden;
}

.worldWater-side-panel {
	background: var(--base05);
}

.ptr-center-column {
	height: 100%;
}

.ptr-layout-columns {
	display: flex;
	flex-direction: column;
}
