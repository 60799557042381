@use 'sass:math';
@import '../../../../../styles/variables';

.worldWater-ButtonSwitch.worldWater-LevelSwitch {
  font-family: "Sen" , "Roboto", sans-serif;
  overflow: hidden;
	@include maxWidth('small') {
		height: 2.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}

// General styling for world cereal button switch
.worldWater-ButtonSwitch {
  height: 1.85rem;

  @include maxWidth('medium') {
    height: 1.5rem;
  }
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button {
  border-radius: .1rem;
  border: 1px solid $borderColor_accented;
  min-height: 1.8rem;

  @include maxWidth('medium') {
    min-height: 1.5rem;
  }
	@include maxWidth('small') {
		min-height: 1.25rem;
		padding: 0 0.1rem;
	}
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button:hover {
  background: $backgroundColor_light;
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button .ptr-button-caption {
  padding: .35rem .1rem .35rem;
  color: $textColor_secondary;

  @include maxWidth('medium') {
    font-size: $b1;
    padding: .35rem 0 .35rem;
    margin: 0;
  }

	@include maxWidth('x-small') {
    font-size: 0.66rem;
    padding: .2rem 0 .2rem;
    margin: 0;
  }
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button .ptr-button-caption:not(.alternative) {
  display: block;
  @include maxWidth('x-large') {
    display: none;
  }
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button .ptr-button-caption.alternative {
  display: none;
  @include maxWidth('x-large') {
    display: block;
  }
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button.active {
  background: $backgroundColor_accented;
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button.active .ptr-button-caption {
  color: $textColor_inverted;
  font-weight: bold;
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button.disabled {
  border: 1px solid $borderColor_disabled;
}

.worldWater-ButtonSwitch.ptr-button-switch .ptr-button.disabled .ptr-button-caption {
  color: $textColor_disabled;
}

.worldWater-ButtonSwitch.ptr-button-group .ptr-button:not(:last-child), .worldWater-ButtonSwitch.ptr-button-group .ptr-button:not(:last-child):after {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.worldWater-ButtonSwitch.ptr-button-group .ptr-button:not(:first-child), .worldWater-ButtonSwitch.ptr-button-group .ptr-button:not(:first-child):after {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}