@use 'sass:math';
@import '../../../../../../../styles/variables';

$SectionHeading: "Sen", "Roboto", sans-serif;

.worldWater-StatisticsSection {
    width: 100%;
    background: var(--base0);
	display: flex;
	flex-direction: column;
	//padding: 1rem;
}

.worldWater-StatisticsSection-header {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: $StatisticsPanelSection_padding $StatisticsPanelSection_padding .85rem $StatisticsPanelSection_padding;

	@include maxWidth('medium') {
		padding: $StatisticsPanelSection_padding_medium;
	}
	@include maxWidth('x-small') {
		padding: $StatisticsPanelSection_padding_xsmall;
	}
}

.worldWater-StatisticsSection-headerText {
	margin: 0;
	font-family: $SectionHeading;
	color: $textColor_base;
	@include maxWidth('medium') {
		font-size: 1.15rem;
	}
	@include maxWidth('x-small') {
		font-size: .95rem;
		letter-spacing: -.5px;
	}
}

.worldWater-StatisticsSection-headerTools {
	display: flex;
	flex-direction: row;
	margin: -.5rem -0.5rem -.5rem 0;
}

.worldWater-StatisticsSection-content {
	overflow: hidden;
	transition: 0.5s;
}

.worldWater-StatisticsSection-content.is-minimised {
	max-height: 0rem;
}

.worldWater-StatisticsSection-contentWrapper {
	padding: 0 $StatisticsPanelSection_padding 0.65rem;
	@include maxWidth('medium') {
		padding: 0 $StatisticsPanelSection_padding_medium 0.5rem;
	}
	@include maxWidth('x-small') {
		padding: 0 $StatisticsPanelSection_padding_xsmall .25rem;
	}
}

.worldWater-StatisticsSection-contentToggle {
	width: 100%;
	margin-top: 1rem;
	display: flex;
	justify-content: flex-start;
	@include maxWidth('x-small') {
		margin-top: .5rem;
	}
	
}

.worldWater-StatisticsSection-contentToggleBtn {
	display: flex;
	align-items: center;
	cursor: pointer;
	font-size: $a1;
	color: $textColor_secondary;
	@include maxWidth('x-small') {
		font-size: $b1;
	}
}

.worldWater-StatisticsSection-contentToggleIcon {
	margin: 0 0 0 0.2rem;
	transition: 0.5s;
}

.worldWater-StatisticsSection-contentToggleIcon.is-open {
	transform: rotate(180deg);
}