@use 'sass:math';
@import '../../../../../styles/variables';

.worldWater-PeriodSwitch {
  font-family: "Sen" , "Roboto", sans-serif;
  overflow: hidden;
  pointer-events: none;
	@include maxWidth('small') {
		height: 2.5rem;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}