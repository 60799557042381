@use 'sass:math';
@import '../../../../../../styles/variables.scss';

.worldWater-Toolbar {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 .45rem;
	@include maxWidth('medium') {
		padding: 0 .2rem;
	}
	@include maxWidth('x-small') {
		padding: 0;
	}
}
